<template>
  <div class="card pt-5 pb-5">
    <validation-observer ref="changePassword">
      <b-form class="row">
        <div class="col-md-10 col-sm-12 m-auto">
          <div class="row">
            <div class="col-12">
              <h3 class="mb-1">{{ $t("Keep your account Secure!") }}</h3>
              <h3 class="mb-2">{{ $t("Update your Password") }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label-for="oldPassword" label="Old password">
                <validation-provider
                  #default="{ errors }"
                  name="Old password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="old_password"
                      v-model="formData.oldPassword"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ wrongPassword }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-form-group label-for="password" :label="$t('New password')">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('New password')"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="formData.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <!-- password confirmation-->
              <b-form-group
                label-for="password-confirmation"
                label="Password Confirmation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password Confirmation"
                  rules="required|confirmed:New password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password-confirmation"
                      v-model="formData.confirmPassword"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-button
                class="mr-2 footer-btn"
                variant="primary"
                type="submit"
                :disabled="submitLoader"
                @click.prevent="validationForm()"
              >
                Save Changes
              </b-button>

              <b-button
                class="footer-btn"
                @click="close"
                type="submit"
                variant="outline-primary"
                :class="formData.published === 0 ? 'ml-2' : ''"
                :disabled="submitLoader"
                @click.prevent
              >
                Back
              </b-button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import UserService from "@/services/UserService";

export default {
  name: "ChangePassword",
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userService: new UserService(),
      submitLoader: false,
      formData: {
        oldPassword: "",
        password: "",
        confirmPassword: "",
      },
      required,
      wrongPassword: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validationForm() {
      console.log(this.$refs.changePassword);
      this.$refs.changePassword.validate().then((success) => {
        if (success) {
          this.submitLoader = true;
          this.wrongPassword = "";
          this.userService.changePassword(this.formData).then(
            (res) => {
              this.submitLoader = false;
              localStorage.setItem("accessToken", res.user.token);
              this.$router.go();
            },
            (error) => {
              this.submitLoader = false;
              if (error.statusCode === 400) {
                this.wrongPassword = error.message;
              }
            }
          );
        }
      });
    },
    close() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
</style>
